<template>
  <!-- <sdPageHeader title="Select Game" /> -->
  <div class="game-container">
    <div class="admin-banner-container">
      <div class="bannet-title">Choose your theme</div>
      <div class="bannet-note">for your gamification campaign...</div>
    </div>
    <Main>
      <a-row :gutter="25">
        <a-col
          :xxl="6" :lg="6" :sm="6" :xs="24"
          v-for="theme of themes"
          :key="theme.encrypt_id"
        >
          <GameCardStyle @click="handleThemeSelect(theme)" :style="{ marginBottom: '25px' }">
            <img
              :src="theme.image"
              :alt="theme.name"
            />
            <div class="game-name">{{theme.name}}</div>
            <div v-if="selectedTheme == theme.encrypt_id" class="selected-game">
              <LoadingOutlined style="color: red; font-size: 50px;" v-if="campaignCreating"/>
              <i v-else class="fa fa-check-circle select-icon-class"/>
            </div>
          </GameCardStyle>
        </a-col>
      </a-row>
      <div v-if="!fetching" class="d-flex align-items-center justify-content-center">
        <sdButton @click="goBack" size="small" type="primary">
          Back
        </sdButton>
      </div>
    </Main>
  </div>
</template>

<script>
import _ from "lodash";
import { LoadingOutlined } from '@ant-design/icons-vue';

import { Main } from "../../styled";
import { GameCardStyle } from "./style";

import { API } from "@/services/api";
import Notification from '@/services/Notification';
export default {
  name: "SelectTheme",
  components: {
    Main,
    GameCardStyle,
    LoadingOutlined
  },
  data() {
    return {
      selectedTheme: null,
      currentPage: 1,
      perPage: 12,
      themes: [],
      fetching: true,
      campaignCreating: false,
    };
  },
  computed: {
    gameData: function() {
      return this.$store.getters.gameData;
    },
    initial: function() {
      return this.$store.getters.initial;
    }
  },
  methods: {
    async fetchThemes() {
      try {
        const params = {
          limit: this.perPage,
          page: this.currentPage,
          game: this.gameData.encrypt_id,
        };
        const resData = await API.getThemes({params});
        const { data = {}, status, message = "" } = resData;
        if (status) {
          const { themes } = data?.data ?? {};
          this.themes = themes;
        } else if (!status && message) {
          Notification.show(message, {type: "error", title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    async handleThemeSelect(theme) {
      if (this.selectedTheme == theme.encrypt_id) {
        this.selectedTheme = null;
      } else {
        try {
          this.selectedTheme = theme.encrypt_id;
          this.$store.commit("setTheme", theme);
          this.campaignCreating = true;
          const fData = new FormData();
          fData.append("game_id", this.gameData.encrypt_id);
          fData.append("theme_id", theme.encrypt_id);
  
          const dataRes = await API.commitCampaign(fData);
          const { status, encrypt_id = "", message } = dataRes.data;

          if (message && !status) {
            if (!_.isEmpty(message) && _.isObject(message)) {
              for (const d of Object.values(message)) {
                const msg = d[0];
                if (msg) {
                  setTimeout(() => {
                    Notification.show(msg, { type: "error", title: "Error" })
                  }, 200);
                }
              }
            } else {
              Notification.show(message, {type: "error", title: "Error"});
            }
          }

          if (status) {
            this.$router.push({path: `/${this.initial}/campaigns/setup/${encrypt_id}`});
          }
        } catch (error) {
          if (error?.message) {
            Notification.show(error.message, {type: "error", title: "Error"});
          }
        } finally {
          this.campaignCreating = false;
        }
      }
    },
    navigateToCreateCampaign() {
      if (!this.selectedTheme) {
        Notification.show("Please select any one theme", {type: "info", title: "Select Theme"});
        return null;
      }
      this.$store.commit("setTheme", this.themes.find(t => t.encrypt_id == this.selectedTheme));
      this.$router.push({path: `/${this.initial}/campaigns/setup`});
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  beforeMount() {
    if (_.isEmpty(this.gameData)) {
      this.$router.go(-1);
      return null;
    }

    this.fetchThemes();
  }
}
</script>